import * as Animate from "../../page/animate";
import * as Icons from "../../page/icons";
import * as React from "react";

import { DefaultIcons, IconSelect } from "../../page/assetpublisher/index";
import { FormSpec, IconColoring, getHelpId, localized, multiSelectStylePicker } from "../../../form-specs";
import { LocalizedContentBase, Site, WithId } from "@maxxton/cms-api";
import { PageWidgetSpec, Widget, isPageWidget, reportWidgetRenderError } from "../../";
import { ResultOptions, TypesearchContainerWidgetSpec, WidgetSpec, WidgetType, isResultsPanelWidget } from "../../widget";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { FlexboxOptions } from "../../page/flexbox";
import { PermissionType } from "@maxxton/cms-mxts-api";
import { Revealer } from "./Revealer";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import namespaceList from "../../../i18n/namespaceList";

interface LocalizedBaseOptions extends LocalizedContentBase {
    label: string;
}

interface BaseOptions<LO> {
    showLess: LO[];
    showMore: LO[];
}

interface LocalizedOptions {
    showLess: LocalizedBaseOptions | undefined;
    showMore: LocalizedBaseOptions | undefined;
}

interface LocalizedCloseLabel extends LocalizedContentBase {
    closeLabel: string;
}
export interface WidgetOptions extends BaseOptions<LocalizedBaseOptions> {
    ElementToToggle: string;
    ModalTitle: string;
    animateProperties: string;
    autoPopup: boolean;
    autoPopupDelay: boolean;
    autoPopupOnScroll: boolean;
    autoPopupOnScrollHeight: number;
    autopopupDelayCount: number;
    borderAndShadow: boolean;
    bottom: string;
    completeTextClickable?: boolean;
    disableToggle: boolean;
    exitIntentPopup: boolean;
    iconToRight: boolean;
    left: string;
    modalSize: string;
    modalTitle: LocalizedBaseOptions[] | undefined;
    plusIcon: boolean;
    popupCentered: boolean;
    popupMode: string;
    repeatFirstContentInModal?: boolean;
    right: string;
    saveStatePopup: boolean;
    showAnimate: boolean;
    simpleToggle: boolean;
    styleIds: any[];
    toggleBehavior: string;
    toggleButton: boolean;
    top: string;
    useAsAccordian: boolean;
    useAsButton: boolean;
    showFilterCountInShowMoreButton: boolean;
    openByDefault: boolean;
    openOnUrlIdMatch?: boolean;
    useAsModal: boolean;
    useAsSingleButton: boolean;
    useDynamicContent: boolean;
    iconColor: IconColoring;
    iconPropertiesForMore: string;
    iconPropertiesForLess: string;
    enableCancellationFundAddOn: boolean;
    shadowHeight: number;
    notificationPopup: boolean;
    notificationPosition: string;
    popUpPosition: string;
    name: string;
    useAsFeedbackPopup: boolean;
    localizedCloseText: LocalizedCloseLabel[];
    hideFirstContent: boolean;
}

export enum ModalPosition {
    TOP = "top",
    BOTTOM = "bottom",
    LEFT = "left",
    RIGHT = "right",
}

const TARGETS = ["revealer-link"];

// Import animations
const animateList = Object.keys(Animate.default).map((key: any) => ({ label: (Animate.default as any)[key], value: key }));
// Import icons
const iconList: IconSelect[] = Object.keys(Icons.default).map((key: string) => ({ label: (Icons.default as DefaultIcons)[key], value: key }));
export function getLocalizedOptions<T extends LocalizedBaseOptions>(context: CMSProvidedProperties, options: BaseOptions<T>): LocalizedOptions | undefined {
    const showLesslocaleOps = options.showLess || [];
    const showLesslocaleOptions = showLesslocaleOps.find((l) => l.locale === context.currentLocale.locale);
    const showMorelocaleOps = options.showMore || [];
    const showMorelocaleOptions = showMorelocaleOps.find((l) => l.locale === context.currentLocale.locale);
    return !!showLesslocaleOptions && !!showMorelocaleOptions
        ? { showLess: showLesslocaleOptions, showMore: showMorelocaleOptions }
        : { showLess: { label: "", locale: "" }, showMore: { label: "", locale: "" } };
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "revealer-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetRevealer, "revealerWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetRevealer, "revealerWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetRevealer, "general"),
                    properties: [
                        [
                            {
                                variable: "name",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "name"),
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.widgetRevealer, "placeholderRevealerText"),
                            },
                            localized({
                                variable: "showLess",
                                tabContent: [
                                    {
                                        variable: "label",
                                        label: getI18nLocaleObject(namespaceList.widgetRevealer, "showLess"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForEmptyText"),
                                    },
                                ],
                            }),
                            localized({
                                variable: "showMore",
                                tabContent: [
                                    {
                                        variable: "label",
                                        label: getI18nLocaleObject(namespaceList.widgetRevealer, "showMore"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForEmptyText"),
                                    },
                                ],
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "useDynamicContent"),
                                variable: "useDynamicContent",
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),

                            {
                                // plusIcon variable will be use as show an icon
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconTitle"),
                                variable: "plusIcon",
                                type: "checkbox",
                                groupName: "iconGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "iconGroupTitle"),
                            },
                            {
                                variable: "iconPropertiesForLess",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconForLess"),
                                type: "select",
                                groupName: "iconGroup",
                                optionList: iconList,
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                variable: "iconPropertiesForLess",
                                type: "icons",
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                variable: "iconPropertiesForMore",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconForMore"),
                                type: "select",
                                optionList: iconList,
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                variable: "iconPropertiesForMore",
                                type: "icons",
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconToRight"),
                                variable: "iconToRight",
                                type: "checkbox",
                                groupName: "iconGroup",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "borderAndShadow"),
                                variable: "borderAndShadow",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "shadowHeight"),
                                variable: "shadowHeight",
                                type: "number",
                                visible: (options: WidgetOptions) => options.borderAndShadow,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "popupCentered"),
                                variable: "popupCentered",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.useAsModal,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetRevealer, "option"),
                    help: getHelpId("fMED5G-reveal-option"),
                    properties: [
                        [
                            {
                                variable: "useAsModal",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "useAsModal"),
                                type: "checkbox",
                                groupName: "useAsModalRevealerGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetRevealer, "useAsModalRevealerGroupTitle"),
                            },
                            {
                                variable: "notificationPopup",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "notificationPopup"),
                                type: "checkbox",
                                groupName: "useAsModalRevealerGroup",
                                visible: (options: WidgetOptions) => options.useAsModal,
                            },
                            {
                                variable: "notificationPosition",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "notificationPosition"),
                                type: "select",
                                groupName: "useAsModalRevealerGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.notificationPopup,
                                optionList: [
                                    {
                                        value: ModalPosition.TOP,
                                        label: getI18nLocaleObject(namespaceList.admin, "top"),
                                    },
                                    {
                                        value: ModalPosition.BOTTOM,
                                        label: getI18nLocaleObject(namespaceList.admin, "bottom"),
                                    },
                                ],
                            },
                            {
                                variable: "useAsFeedbackPopup",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsFeedbackPopup"),
                                type: "checkbox",
                                groupName: "useAsModalRevealerGroup",
                                visible: (options: WidgetOptions) => options.useAsModal,
                            },
                            {
                                variable: "showAnimate",
                                label: getI18nLocaleObject(namespaceList.admin, "showAnimate"),
                                type: "checkbox",
                                groupName: "useAsModalRevealerGroup",
                                visible: (options: WidgetOptions) => options.useAsModal,
                            },
                            {
                                variable: "animateProperties",
                                label: getI18nLocaleObject(namespaceList.admin, "animateProperty"),
                                type: "select",
                                optionList: animateList,
                                groupName: "useAsModalRevealerGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.showAnimate,
                            },
                            {
                                variable: "animateProperties",
                                type: "animate",
                                groupName: "useAsModalRevealerGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.showAnimate,
                            },
                            {
                                variable: "useAsButton",
                                label: getI18nLocaleObject(namespaceList.admin, "useAsButton"),
                                type: "checkbox",
                                groupName: "useAsButtonRevealerGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetRevealer, "useAsButtonRevealerGroupTitle"),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "showFilterCountInShowMoreButton"),
                                variable: "showFilterCountInShowMoreButton",
                                type: "checkbox",
                                groupName: "useAsButtonRevealerGroup",
                                visible: (options: WidgetOptions) => options.useAsButton,
                            },
                            {
                                variable: "useAsAccordian",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "useAsAccordion"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useAsModal,
                            },
                            {
                                variable: "toggleButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "toggleButton"),
                                type: "checkbox",
                                groupName: "toggleTheBlockGroupName",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "toggleTheBlockGroupTitle"),
                            },
                            {
                                variable: "ElementToToggle",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "ElementToToggle"),
                                type: "text",
                                groupName: "toggleTheBlockGroupName",
                                visible: (item: any) => item.toggleButton,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderElementToggle"),
                            },
                            {
                                variable: "toggleBehavior",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "toggleBehavior"),
                                type: "select",
                                groupName: "toggleTheBlockGroupName",
                                optionList: [
                                    {
                                        value: "toggleLeft",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "toggleLeft"),
                                    },
                                    {
                                        value: "toggleRight",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "toggleRight"),
                                    },
                                    {
                                        value: "toggleBottom",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "toggleBottom"),
                                    },
                                ],
                                visible: (item: any) => item.toggleButton,
                            },
                            {
                                variable: "openByDefault",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "openByDefault"),
                                type: "checkbox",
                            },
                            {
                                variable: "openOnUrlIdMatch",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "openOnUrlIdMatch"),
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "toggleDesc"),
                                type: "paragraph",
                            },
                            {
                                variable: "simpleToggle",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "simpleToggle"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useAsModal,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "popup"),
                    visible: (options: WidgetOptions) => options.useAsModal,
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "repeatFirstContentInModal"),
                                variable: "repeatFirstContentInModal",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.useAsModal,
                            },
                            {
                                variable: "hideFirstContent",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "hideFirstContent"),
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "autopopup"),
                                variable: "autoPopup",
                                type: "checkbox",
                                groupName: "autoPopupGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetRevealer, "autoPopupGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.widgetRevealer, "autoPopupGroupDescription"),
                                visible: (options: WidgetOptions) => options.useAsModal && !options.exitIntentPopup,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "autoPopupDelay"),
                                variable: "autoPopupDelay",
                                type: "checkbox",
                                groupName: "autoPopupGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.autoPopup,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "autoPopupScrollUse"),
                                type: "paragraph",
                                groupName: "autoPopupGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.autoPopup && options.autoPopupDelay && options.autoPopupOnScroll,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "autopopupDelayCount"),
                                variable: "autopopupDelayCount",
                                type: "number",
                                groupName: "autoPopupGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.autoPopup && options.autoPopupDelay,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "autoPopupOnScroll"),
                                variable: "autoPopupOnScroll",
                                type: "checkbox",
                                groupName: "autoPopupGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.autoPopup,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "autoPopupDelayUse"),
                                type: "paragraph",
                                groupName: "autoPopupGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.autoPopup && options.autoPopupDelay && options.autoPopupOnScroll,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "autoPopupOnScrollHeight"),
                                variable: "autoPopupOnScrollHeight",
                                type: "number",
                                groupName: "autoPopupGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.autoPopup && options.autoPopupOnScroll,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "saveStatePopup"),
                                variable: "saveStatePopup",
                                type: "checkbox",
                                groupName: "autoPopupGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && options.autoPopup,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "exitIntentPopup"),
                                variable: "exitIntentPopup",
                                type: "checkbox",
                                groupName: "autoPopupGroup",
                                visible: (options: WidgetOptions) => options.useAsModal && !options.autoPopup,
                            },
                            {
                                variable: "disableToggle",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "disableToggle"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.useAsModal,
                            },
                        ],
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "completeTextClickable"),
                                variable: "completeTextClickable",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.useAsModal,
                            },
                            {
                                variable: "modalSize",
                                label: getI18nLocaleObject(namespaceList.admin, "modalSize"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.useAsModal,
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.admin, "default"),
                                    },
                                    {
                                        value: "content-fit",
                                        label: getI18nLocaleObject(namespaceList.admin, "contentPopupFit"),
                                    },
                                    {
                                        value: "sm",
                                        label: getI18nLocaleObject(namespaceList.admin, "small"),
                                    },
                                    {
                                        value: "md",
                                        label: getI18nLocaleObject(namespaceList.admin, "medium"),
                                    },
                                    {
                                        value: "lg",
                                        label: getI18nLocaleObject(namespaceList.admin, "large"),
                                    },
                                    {
                                        value: "full",
                                        label: getI18nLocaleObject(namespaceList.admin, "full"),
                                    },
                                ],
                            },
                            {
                                variable: "popUpPosition",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "popUpPosition"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.useAsModal && !options.notificationPopup,
                                optionList: [
                                    {
                                        value: ModalPosition.LEFT,
                                        label: getI18nLocaleObject(namespaceList.admin, "left"),
                                    },
                                    {
                                        value: ModalPosition.RIGHT,
                                        label: getI18nLocaleObject(namespaceList.admin, "right"),
                                    },
                                ],
                            },
                            localized({
                                variable: "localizedCloseText",
                                visible: (options: WidgetOptions) => options.useAsModal,
                                tabContent: [
                                    {
                                        variable: "closeLabel",
                                        label: getI18nLocaleObject(namespaceList.widgetRevealer, "closeTextLabel"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForEmptyText"),
                                    },
                                ],
                            }),
                            {
                                variable: "useAsSingleButton",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "useAsSingleButton"),
                                visible: (options: WidgetOptions) => options.useAsModal,
                                type: "checkbox",
                            },
                            localized({
                                variable: "modalTitle",
                                visible: (options: WidgetOptions) => options.useAsModal,
                                tabContent: [
                                    {
                                        variable: "label",
                                        label: getI18nLocaleObject(namespaceList.widgetRevealer, "modalTitle"),
                                        type: "text",
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForTitle"),
                                    },
                                ],
                            }),
                            {
                                variable: "ModalTitle",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "ModalTitle"),
                                visible: (options: WidgetOptions) => options.useAsModal,
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForTitle"),
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

// eslint-disable-next-line max-lines-per-function
export function revealerWidget(type: WidgetType) {
    const revealer: WidgetSpec<WidgetOptions> = {
        id: "revealer",
        type,
        widgetGroup: WidgetGroup ? (type === "page" || type === "form" || type === "resultsPanel" ? WidgetGroup.LAYOUT : WidgetGroup.OTHER) : 2,
        name: getI18nLocaleObject(namespaceList.widgetRevealer, "revealerWidget"),
        description: getI18nLocaleObject(namespaceList.widgetRevealer, "revealerWidgetDescription"),
        async instanceDescription({ widget }): Promise<string> {
            const { name } = widget.options || "";
            if (name) {
                return name;
            }
            return getI18nLocaleString(namespaceList.widgetRevealer, "revealerWidgetDescription");
        },
        optionsForm: widgetOptionsForm,
        defaultOptions: (): WidgetOptions => ({
            ElementToToggle: "",
            ModalTitle: "",
            animateProperties: "",
            autoPopup: false,
            autoPopupDelay: false,
            autoPopupOnScroll: false,
            autoPopupOnScrollHeight: 0,
            autopopupDelayCount: 0,
            borderAndShadow: false,
            bottom: "",
            completeTextClickable: false,
            disableToggle: false,
            exitIntentPopup: false,
            iconToRight: false,
            left: "",
            modalSize: "lg",
            modalTitle: [],
            plusIcon: false,
            popupCentered: false,
            popupMode: "top",
            right: "",
            saveStatePopup: false,
            showAnimate: false,
            showLess: [],
            showMore: [],
            simpleToggle: false,
            styleIds: [],
            toggleBehavior: "",
            toggleButton: false,
            top: "",
            useAsAccordian: false,
            useAsButton: false,
            showFilterCountInShowMoreButton: false,
            openByDefault: false,
            useAsModal: false,
            useAsSingleButton: false,
            useDynamicContent: false,
            iconColor: "color-brand",
            iconPropertiesForMore: "",
            iconPropertiesForLess: "",
            enableCancellationFundAddOn: false,
            shadowHeight: 0,
            notificationPopup: false,
            notificationPosition: "top",
            popUpPosition: "",
            name: "",
            useAsFeedbackPopup: false,
            localizedCloseText: [],
            hideFirstContent: false,
        }),
    };
    (revealer as PageWidgetSpec<WidgetOptions> | TypesearchContainerWidgetSpec<WidgetOptions>).render = async (
        widget: Widget<WidgetOptions>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions,
        shouldReturnProps?: boolean,
        allSites?: Array<Site & WithId>,
        flexboxOptions?: FlexboxOptions
    ) => {
        const children: JSX.Element[] = await Promise.all(
            widget.children.map((child, index) => {
                const childSpec = child.spec;
                if (isPageWidget(childSpec)) {
                    return childSpec.render(child, context, sitemapPageLinkWidgetOptions, resultOptions, dynamicContainerOptions, shouldReturnProps, allSites, flexboxOptions).catch((err) => {
                        reportWidgetRenderError(revealer, err, childSpec, context);
                        return <div key={index} />;
                    });
                } else if (isResultsPanelWidget(childSpec)) {
                    return childSpec.render(child, context, sitemapPageLinkWidgetOptions, resultOptions, dynamicContainerOptions).catch((err) => {
                        reportWidgetRenderError(revealer, err, childSpec, context);
                        return <div key={index} />;
                    });
                }
                throw new TypeError("Expected child widgets to be page widgets");
            })
        );
        const localeOptions = getLocalizedOptions(context, widget.options);
        const showLessLabel = localeOptions && localeOptions.showLess ? localeOptions.showLess.label : "";
        const showMoreLabel = localeOptions && localeOptions.showMore ? localeOptions.showMore.label : "";
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        return (
            <Revealer
                permission={PermissionType.EXECUTE}
                id={widget._id}
                childs={children}
                options={widget.options}
                showLess={showLessLabel}
                showMore={showMoreLabel}
                className={styleClasses}
                flexboxOptions={flexboxOptions}
            />
        );
    };
    return revealer;
}
